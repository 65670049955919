import React, { useState } from "react";
import { Col, Container, InputGroup, Form, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

import footerbadge from "assets/SOC2Badge.png";
export default function Footer() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  // const values = [true];
  // const [fullscreen, setFullscreen] = useState(true);
  // const [show, setShow] = useState(false);

  // function handleShow(breakpoint) {
  //   setFullscreen(breakpoint);
  //   setShow(true);
  // }
  return (
    <>
      <footer>
        <Container>
          {/* <>
            {values.map((v, idx) => (
              <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
                Full screen
              </Button>
            ))}
            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Modal</Modal.Title>
              </Modal.Header>
              <Modal.Body>Modal body content</Modal.Body>
            </Modal>
          </> */}
          <Row>
            <Col md={4}>
              <div className="ftr-left">
                <p>777 West Putnam Avenue,<br/>Suite 300,<br/>Greenwich, CT 06830</p>
                <div className="footer_youtube_icon">
                  <Link to="/">
                    <FontAwesomeIcon icon={brands("youtube")} style={{ color: "#f00000", }} />
                  </Link>
                  <Link to="/">
                    <FontAwesomeIcon icon={brands("x-twitter")} />
                  </Link>
                  <Link to="/">
                    <FontAwesomeIcon icon={brands("linkedin")} style={{ color: "#0072b1 ", }} />
                  </Link>
                </div>
                <p>Copyright © 2024 Data Street Partners. All Rights Reserved.</p>
                {/* <div className="footer_cookies">
                  <Link to="/"> Cookies Settings</Link>
                </div> */}
                {/* <div className="footer_us_link">
                  <ul>
                    <li>
                      <Link to="/">US</Link>
                    </li>
                    <li>
                      <Link to="/">GB</Link>
                    </li>
                    <li>
                      <Link to="/">DE</Link>
                    </li>
                    <li>
                      <Link to="/">JP</Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </Col>
            <Col md={8}>
              <div className="ftr-right">
                <Row>
                  <Col md={3}>
                    <div className="ftr-links">
                      <span>SERVICES</span>
                      <ul>
                        <li>
                          <Link to="/">Solutions</Link>
                        </li>
                        <li>
                          <Link to="/">Capabilities</Link>
                        </li>                        
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="ftr-links">
                      <span>ABOUT US</span>
                      <ul>
                        <li>
                          <Link to="/">Our Partners</Link>
                        </li>
                        <li>
                          <Link to="/">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/">Trust Center</Link>
                        </li>                       
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="ftr-links">
                      <span>CAREERS</span>
                      <ul>
                        <li>
                          <Link to="/">BI Analyst</Link>
                        </li>
                        <li>
                          <Link to="/">Data Scientist</Link>
                        </li>
                        <li>
                          <Link to="/">Engineer</Link>
                        </li>                       
                       </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="ftr-links">
                      <div className="ftr-img">
                        <img src={footerbadge} alt="img" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
