
import { 
  BrowserRouter, 
  Routes, 
  Route 
} from "react-router-dom";
import Home from "pages/Home";
import "./App.css";
import Header from "components/Header";
import About from "pages/About";
import Footer from "components/Footer";


function App() {
  return (
    <BrowserRouter>
      <header className="pageheader">
      <Header />
      
      </header>
      <Routes>
        <Route path="/" element={<Home />} />        
        <Route path="/about" element={<About />} />                
      </Routes>
      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
}

export default App;
