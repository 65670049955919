import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col, Button, Nav, Navbar, Offcanvas } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from "assets/logo.png";
import logowhite from "assets/logowhite.png";
import headerblog1 from "assets/header-blog-img-1.jpg";
import headerblog2 from "assets/header-blog-img-2.jpg";
import Launchpad from "assets/TGH_Launchpad.png";


export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showOffcanvasMenu, setShowOffcanvasMenu] = useState(false);

  const openModal1 = () => {
    setShowModal1(true);
    setShowModal2(false); // Close modal 2
    setShowOffcanvasMenu(false); // Close offcanvas menu
  };
  const closeModal1 = () => {
    setShowModal1(false);
  };

  const openModal2 = () => {
    setShowModal1(false); // Close modal 1
    setShowModal2(true);
    setShowOffcanvasMenu(false); // Close offcanvas menu
  };
  const closeModal2 = () => {
    setShowModal2(false);
  };

  const openOffcanvasMenu = () => {
    setShowModal1(false); // Close modal 1
    setShowModal2(false); // Close modal 2
    setShowOffcanvasMenu(true);
  };

  const closeAll = () => {
    setShowModal1(false);
    setShowModal2(false);
    setShowOffcanvasMenu(false);
  };

  return (
    <>
      <header>
        <Container>
          <div className="header-top">
            <Row>
              <Col md={6}>
                <div className="header-logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="header-nav">
                  <div className="start-btn">
                    <Button
                      variant="primary"
                      onClick={openOffcanvasMenu}
                      //onClick={handleShow}
                      className="btn-style btn-black-outline"
                    >
                      <FontAwesomeIcon icon={solid("arrow-up")} /> Get Started
                    </Button>
                  </div>
                  <div className="menu-btn">

                    <Button className="btn-style btn-black-outline" onClick={openModal1}>
                      <FontAwesomeIcon icon={solid("search")} />
                    </Button>
                    <Button className="btn-style btn-black-outline" onClick={openModal2}>
                      <FontAwesomeIcon icon={solid("bars")} />
                    </Button>

                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="header-bottom">
            <Navbar expand="lg" className="bg-body-tertiary">
             
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Services</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>About</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Solutions</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Industries</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Trust Center</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Career</NavLink>
                  </Nav>
                </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </header>

      <Offcanvas show={showOffcanvasMenu} onHide={closeAll} placement="end">
        <Offcanvas.Header closeButton><Offcanvas.Title></Offcanvas.Title></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="Offcanvas_contact">
            <span>CONTACT / DEMO REQUEST + PARTNERSHIP INQUIRY</span>
            <span className="Offcanvas_contact_link">
              <NavLink exact='true' as={Link} to={'/'} >INVESTOR RELATIONS <FontAwesomeIcon icon={solid("arrow-up")} /> </NavLink>
            </span>

          </div>
          <div className="Offcanvas_main_title">
            <h2>Interested in solving your problems</h2>
          </div>

          <Form className="Offcanvas_form">
            <Form.Group className="mb-4 Offcanvas_form_input" controlId="">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                FIRST NAME:
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="mb-4 Offcanvas_form_input" controlId="">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                FIRST NAME:
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="mb-4 Offcanvas_form_input" controlId="formBasicEmail">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                BUSINESS EMAIL ADDRESS:
              </Form.Label>
              <Form.Control type="email" />
            </Form.Group>

            <Form.Group className="mb-4 Offcanvas_form_input" controlId="">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                PHONE NUMBER:
              </Form.Label>
              <Form.Control type="number" />
            </Form.Group>

            <Form.Group className="mb-4 Offcanvas_form_input" controlId="">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                JOB TITLE:
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="mb-4 Offcanvas_form_input" controlId="">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                COMPANY / INSTITUTION:
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="mb-4 Offcanvas_form_input" controlId="">
              <Form.Label className="Offcanvas_form_label">
                <div className="mktoAsterix">*</div>
                COUNTRY:
              </Form.Label>
              <Form.Select>
                <option>Open this select</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-4" controlId="floatingTextarea2">
              <Form.Label className="Offcanvas_form_label">
                TELL US ABOUT YOUR PROJECT, A BIT OF CONTEXT WILL ALLOW US TO CONNECT YOU TO THE RIGHT TEAM FASTER:
              </Form.Label>

              <FloatingLabel controlId="floatingTextarea2">
                <Form.Control as="textarea" style={{ height: '100px' }} />
              </FloatingLabel>
            </Form.Group>
            <Button type="submit" className="btn-style btn-black-outline btn btn-primary" >Submit</Button>
          </Form>
          <div className="Offcanvas_footer">
            <span>Please see our Privacy Policy regarding how we will handle this information.</span>
          </div>

        </Offcanvas.Body>
      </Offcanvas>

      {/* SEARCH MODAL */}
      <Modal fullscreen={true} show={showModal1} onHide={closeAll}>
        <Container>
          <div className="header-top">
            <Row>
              <Col md={6}>
                <div className="header-logo">
                  <Link to="/">
                    <img src={logowhite} alt="logo" />
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="header-nav">
                  <div className="start-btn">
                    <Button
                      variant="primary"
                      onClick={openOffcanvasMenu}
                      className="btn-style2 btn-black-outline2"
                    >
                      <FontAwesomeIcon icon={solid("arrow-up")} /> Get Started
                    </Button>
                  </div>
                  <div className="menu-btn">

                    <Button className="btn-style2 btn-black-outline2" onClick={closeModal1}>
                      <FontAwesomeIcon icon={solid("xmark")} />
                    </Button>
                    <Button className="btn-style2 btn-black-outline2" onClick={openModal2}>
                      <FontAwesomeIcon icon={solid("bars")} />
                    </Button>
                  </div>
                </div>
              </Col>
              <Form className="search_model" >
                <Form.Group className="mb-4 Offcanvas_form_input2 Offcanvas_form_input" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Start typing to search"  />
                </Form.Group>
              </Form>
              <div className="header-bottom">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="nav_modal" >
                    <p>Popular Searches</p>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Business Intelligence</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Advanced Analytics
</NavLink>
                    <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Research & Advisory
</NavLink>

                  </Nav>
                </Navbar.Collapse>
            </Navbar>
          </div>
            </Row>
          </div>
        </Container>
        <Modal.Body></Modal.Body>
      </Modal>
      {/* END SEARCH MODAL */}

      {/* BAR MENU MODAL */}
      <Modal fullscreen={true} show={showModal2} onHide={closeAll}>
        <Container>
          <div className="header-top">
            <Row>
              <Col md={6}>
                <div className="header-logo">
                  <Link to="/">
                    <img src={logowhite} alt="logo" />
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="header-nav">
                  <div className="start-btn2">
                    <Button
                      variant="primary"
                      onClick={openOffcanvasMenu}
                      //onClick={handleShow}
                      className="btn-style2 btn-black-outline2"
                    >
                      <FontAwesomeIcon icon={solid("arrow-up")} /> Get Started
                    </Button>
                  </div>
                  <div className="menu-btn">

                    <Button className="btn-style2 btn-black-outline2" onClick={openModal1}>
                      <FontAwesomeIcon icon={solid("search")} />
                    </Button>
                    <Button className="btn-style2 btn-black-outline2" onClick={closeModal2}>
                      <FontAwesomeIcon icon={solid("xmark")} />
                    </Button>

                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal.Body>
          <div className="bars-modal">
            <Container>
            <Row>
              <Col md={4}>
                <div className="header_navigation_modal">
                  <h6>Navigation</h6>
                  <ul>
                    
                    {/* <li><a href="#">Infrastructure</a></li> */}
                    <li><a href="#">↳ Infrastructure</a></li>
                    <li><a href="#">↳ Data</a></li>
                    <li><a href="#">↳ Reporting and Visualization</a></li>
                    <li><a href="#">↳ Managed Services</a></li>
                    <li><a href="#">↳ Artificial Intelligence</a></li>                   
                    
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className="header_modal_blogs">
                  <div className="latest_news_modal_head">
                    <h6>Latest News</h6>
                    <a href="#">Newsroom <FontAwesomeIcon icon={solid("arrow-right")} /></a>
                  </div>
                  <Row>
                    <Col md={6}>
                      <div className="header_blog_box">
                        <p>November 23, 2023</p>
                        <img src={ headerblog1 } alt="img" />
                        <a href="#">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="header_blog_box">
                        <p>September 27, 2023</p>
                        <img src={ headerblog2 } alt="img" />
                        <a href="#">Lorem Ipsum is simply dummy text of the printing and typesetting industry</a>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div div className="header_impact_study">
                        <div className="impact_study_btn">
                         <a href="#">View All Impact Studies <FontAwesomeIcon icon={solid("arrow-right")} /></a>
                         </div>
                         <div className="header_blog_box">
                        <p> text of the printing and typesetting industry</p>
                        <img src={ Launchpad } alt="img" />
                        <a href="#">Lorem Ipsum is simply dummy</a>
                      </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={4}>
              <div className="header_modal_blogs">
                  <div className="latest_news_modal_head">
                    <h6>Offerings</h6>
                    <a href="#">View All Offerings <FontAwesomeIcon icon={solid("arrow-right")} /></a>
                  </div>
                  <Row>
                    <Col md={12}>
                    <div className="impact_study_btn">
                          <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</h5>
                         <a href="#">↳ Learn more about us</a>
                         </div>
                      <div div className="header_impact_study">
                         <div className="header_blog_box">
                        <p>text of the printing and typesetting industry</p>
                        <img src={ Launchpad } alt="img" />
                        <a href="#">Lorem Ipsum is simply dummy</a>
                      </div>
                      </div>
                    </Col>
                  </Row>
                </div>  
              </Col>
            </Row>
            </Container>
          </div>


        </Modal.Body>
      </Modal>
      {/* END BAR MENU MODAL */}
    </>
  );
}
