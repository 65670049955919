import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import { Player } from "video-react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css";

// MEDIA
import demovideo from "assets/AIDG_1.mp4";
import bootcampimg from "assets/Asset_2AIPBootcamp_Homepage.png";
import poster from "assets/videothumb.jpg";

export default function Home() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const hrTags = document.querySelectorAll(".magic-ani");
      hrTags.forEach((hrTag) => {
        const bounding = hrTag.getBoundingClientRect();
        const topOffset = bounding.top;
        const windowHeight = window.innerHeight;

        if (topOffset < windowHeight) {
          hrTag.classList.add("hrAnimation");
        } else {
          hrTag.classList.remove("hrAnimation");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target;
          animateText(target);
          observer.current.unobserve(target);
        }
      });
    });

    const targets = document.querySelectorAll(".typing-text");
    targets.forEach((target) => observer.current.observe(target));

    return () => observer.current.disconnect();
  }, []);

  const animateText = (target) => {
    const textContent = target.textContent;
    target.textContent = ""; // Clear existing text content

    let index = 0;
    const typingInterval = setInterval(() => {
      target.textContent += textContent[index];
      index++;
      if (index === textContent.length) {
        clearInterval(typingInterval);
      }
    }, 100); // Adjust typing speed as needed
  };

  return (
    <>
      {/* BANNER */}
      <div className="banner">
        <Container>
          <h5>Welcome to</h5>
          <h1 className="typing-text">Data Street Partners</h1>

          <div className="vid-side">
            <Player poster={poster}>
              <source src={demovideo} />
            </Player>
          </div>

          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END BANNER */}

      {/* AI ASSIST */}
      <div className="ai-assist">
        <Container>
          <div className="sec-head">
            <h2 className="typing-text">Who We Are</h2>
          </div>
          <div className="aia-details">
            <Row>
              <Col md={6}>
                <div className="ai-col-01">
                  <p>
                    Data Street Partners blends over 20 years of financial
                    services expertise and advanced technology proficiency
                    revolutionizing operations, enhancing profitability and
                    fostering sustainable growth.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="ai-col-02">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries
                  </p>
                  <Link to="/" className="magic-ani line-btn">
                    Learn more about Data Street Partners{" "}
                    <FontAwesomeIcon icon={solid("arrow-right")} />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END AI ASSIST */}

      {/* BOOTCAMP */}
      <div className="bootcamp">
        <Container>
          <Row>
            <Col md={4}>
              <div className="sec-head">
                <h2 className="typing-text">
                  Data Street Partners Security & Trust Center
                </h2>
                <h4>Your data is our top priority!</h4>
              </div>
              <Link to="/" className="btn-style btn-black">
                Learn More <FontAwesomeIcon icon={solid("arrow-right")} />
              </Link>
            </Col>
            <Col md={8}>
              <div className="img-holder">
                <img src={bootcampimg} alt="img" />
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END BOOTCAMP */}

      {/* AI ASSIST */}
      <div className="ai-assist">
        <Container>
          <div className="sec-head">
            <h2 className="typing-text">Our Digital Trust Mission</h2>
          </div>
          <div className="aia-details">
            <Row>
              <Col md={6}>
                <div className="ai-col-01">
                  <p>
                    Data Street Partners’ digital trust mission is to protect
                    information belonging to its employees, customers, and
                    partners.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="ai-col-02">
                  <p>
                    To that effect, DSP has built a cyber resilient culture,
                    where employees and contractors are not only trained on
                    industry best practices, but also act as first line of
                    defense in detecting and preventing cyber-attacks. Further,
                    DSP has installed technical and procedural controls to
                    prevent the sensitive data and intellectual property from
                    being accessed in an unauthorized manner{" "}
                  </p>
                  {/* <Link to="/" className="magic-ani line-btn">
                    Learn more about our commitment to Artificial Intelligence{" "}
                    <FontAwesomeIcon icon={solid("arrow-right")} />
                  </Link> */}
                </div>
              </Col>
            </Row>
          </div>

          <div className="three-cols">
            <Row>
              <Col md={4}>
                <div className="tc-col">
                  <Link to="/">
                    <span>LOREM IPSUM</span>
                    <h4>Security</h4>
                    <p>
                      "DSP has implemented industry leading security solutions
                      and practices for: "
                    </p>
                    <p>— Learn More</p>
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="tc-col">
                  <Link to="/">
                    <span>LOREM IPSUM</span>
                    <h4>Privacy</h4>
                    <p>
                      "DSP has implemented a formal data governance program that
                      is responsible for overseeing the lifecycle of data that
                      is collected and processed by the organization. The Data
                      Protection practices include:"
                    </p>
                    <p>— Learn More</p>
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="tc-col">
                  <Link to="/">
                    <span>LOREM IPSUM</span>
                    <h4>Compliance</h4>
                    <p>
                      "SOC 2—DSP complies with SSAE 18 standards, and the
                      controls are tested on an annual basis by an independent
                      audit firm. DSP is currently SOC 2 Type 1 compliant, with
                      Security as the Trust Service Principle."
                    </p>
                    <p>— Learn More</p>
                  </Link>
                </div>
              </Col>

              <Col md={4}>
                <div className="tc-col">
                  <Link to="/">
                    <span>LOREM IPSUM</span>
                    <h4>Customer Inquiries</h4>
                    <p>
                      "All inquiries about the digital trust program needs to be
                      directed to security@datastreetpartners.com. In order to
                      manage the volume of inquiries we receive, and to ensure
                      consistency in our approach, DSP has documented responses
                      to the SIG Framework. "
                    </p>
                    <p>— Learn More</p>
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="tc-col">
                  <Link to="/">
                    <span>LOREM IPSUM</span>
                    <h4>Customer Audits</h4>
                    <p>
                      "DSP is fully committed to supporting customer audits.
                      However, due to the volume of requests we receive, we
                      request that you leverage our existing documentation prior
                      to sending us information requests in the form of
                      questionnaires and documentation request lists "
                    </p>
                    <p>— Learn More</p>
                  </Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="tc-col">
                  <Link to="/">
                    <span>LOREM IPSUM</span>
                    <h4>Trust Center Links</h4>
                    <p>
                      "Privacy Policy EULA EULA Subscription Basic Support Terms
                      Basic SLA Terms Help Desk"
                    </p>
                    <p>— Learn More</p>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END AI ASSIST */}

      {/* PLATFORM */}
      <div className="platform">
        <Container>
          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h2>Solutions</h2>
              </div>
            </Col>
            <Col md={6}>
              <h4>
                Over 20 years of financial services expertise and advanced
                technology...
              </h4>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h3>Assessments & Planning</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="linked-text">
                <p>Need a review of your current BI architecure?</p>
                <Link to="/" className="magic-ani">
                  Explore More <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>

          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h3>Tactical Delivery</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="linked-text">
                <p>Onboarding a new technology?</p>
                <Link to="/" className="magic-ani">
                  Explore More <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>

          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h3>Team Augmentation</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="linked-text">
                <p>Need to fill a gap within your team?</p>
                <Link to="/" className="magic-ani">
                  Explore More <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END PLATFORM */}

      {/* DEF TECH */}
      <div className="def-tech">
        <Container>
          <div className="sec-head">
            <h2 className="typing-text">Category-Defining Technology</h2>
          </div>

          <div className="dt-cols">
            <Row>
              <Col md={4}>
                <div className="dt-box">
                  <h4>Lorem Ipsum is simply dummy text</h4>
                  <p>
                    "Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book."
                  </p>
                  <Link to="/">View Full Report</Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="dt-box">
                  <h4>Lorem Ipsum is simply dummy text</h4>
                  <p>
                    "Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book."
                  </p>
                  <p>— Ritu Jyoti, IDC’s Group VP, AI and Automation</p>
                  <Link to="/">Read the Press Release</Link>
                </div>
              </Col>
              <Col md={4}>
                <div className="dt-box">
                  <h4>Lorem Ipsum is simply dummy text</h4>
                  <p>
                    "Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book."
                  </p>
                  <p>— The Forrester Wave™: AI/ML Platforms, Q3 2022</p>
                  <Link to="/">View Full Report</Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END DEF TECH */}

      {/* PLATFORM */}
      <div className="platform">
        <Container>
          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h2>Capabilities</h2>
              </div>
            </Col>
            <Col md={6}>
              <h4>
                Over 20 years of financial services expertise and advanced
                technology...
              </h4>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h3>Business Intelligence</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="linked-text">
                <p>Business Intelligence (BI) is at the heart of what we do.</p>
                <Link to="/" className="magic-ani">
                  Explore More <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>

          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h3>Advanced Analytics</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="linked-text">
                <p>Data science is the new frontier</p>
                <Link to="/" className="magic-ani">
                  Explore More <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>

          <Row>
            <Col md={6}>
              <div className="sec-head">
                <h3>Research & Advisory</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="linked-text">
                <p>Your success is our success</p>
                <Link to="/" className="magic-ani">
                  Explore More <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="divider">
            <div className="magic-ani"></div>
          </div>
        </Container>
      </div>
      {/* END PLATFORM */}
    </>
  );
}
